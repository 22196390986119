/* .vl {
    border-left: 80px solid green;
    height: 1000px;
  } */

  /* .vl {
    border-left: 6px solid green;
    height: 1000px;
    margin-left: 558px;
  } */
  body {
    background-color: #fff;
  }
  .vertical-line {
    width: 1px;
    height: 88%;
    background-color: #ccc;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #long-menu{
    margin-left: 10px;
  }