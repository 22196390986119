// COLOR VARIABLES
$color-primary: #366aaf;
$color-secondary: #3172ba;

$color-light-1: #fff;
$color-light-1: #f2f2f2;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-content {
  height: 15vh;
  width: 100vw;
  background: "#F1F2F6";
  /*display: grid;
  grid-template-rows:
    minmax(min-content, 20vh) 1fr
    minmax(min-content, 20vh);
  grid-template-columns: 100vw;*/
}

.btn-primary {
  background-color: #fff;
  border-radius: 0.25rem;
  color: #366aaf;
  text-transform: uppercase;
  border: none;
  padding: 0.5rem 2rem;
  margin: 0 0.5rem;
  font-family: "Roboto";
  font-weight: 600;

  &:hover {
    background-color: #fff;
    color: #366aaf;
  }
}

a {
  text-decoration: none;
}

// .header-pane {
//   width: 100vw;
//   min-height: 12vh;
// }
.main-pane {
  height: 85vh;
  width: 100vw;
}

.center-pane {
  width: 100%;
  height: 100%;
}

.side-pane {
  width: 100%;
  height: 100%;
  background-color: green;
}

.bg-yellow {
  background-color: yellow;
}

.bg-yellowgreen {
  background-color: yellowgreen;
}

.bg-green {
  background-color: green;
}

.bg-orange {
  background-color: orange;
}

.p-datatable div.p-datatable-header {
  background: #fff;
}

.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-tfoot,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-virtualscroller
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-virtualscroller
  > .p-datatable-table
  > .p-datatable-tfoot {
  background-color: #fff;
}

div.p-datatable-wrapper {
  height: 90vh;
}

.tableBtn .p-button {
  position: inherit !important;
}

.css-bfpls6 {
  position: static !important;
  // color: white !important;
}

//Frozen Postaward Master filing columns
.frozen-mf-pa .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-mf-pa .p-frozen-column:nth-child(5) {
  left: 11rem !important;
}

.frozen-mf-pa .p-frozen-column:nth-child(6) {
  left: 25rem !important;
}

.frozen-mf-pa .filingNameColumn-pa .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen postaward deffered filing column
.frozen-deffered-pa .p-frozen-column:nth-child(2) {
  left: 0 !important;
}

.frozen-deffered-pa .p-frozen-column:nth-child(3) {
  left: 12rem !important;
}

.frozen-deffered-pa .p-frozen-column:nth-child(4) {
  left: 24rem !important;
}

.frozen-deffered-pa .filingNameColumn-pa .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen postaward filing approval pending columns
.frozen-filing-approval-pending-pa .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-filing-approval-pending-pa .p-frozen-column:nth-child(5) {
  left: 12rem !important;
}

.frozen-filing-approval-pending-pa .p-frozen-column:nth-child(6) {
  left: 24rem !important;
}

.frozen-filing-approval-pending-pa .filingNameColumn-pa .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen postaward filing approval completed columns
.frozen-filing-approval-completed-pa .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-filing-approval-completed-pa .p-frozen-column:nth-child(5) {
  left: 12rem !important;
}

.frozen-filing-approval-completed-pa .p-frozen-column:nth-child(6) {
  left: 24rem !important;
}

.frozen-filing-approval-completed-pa
  .filingNameColumn-pa-comp
  .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen Postaward client approval columns
.frozen-client-approval-pa .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-client-approval-pa .p-frozen-column:nth-child(5) {
  left: 12rem !important;
}

.frozen-client-approval-pa .p-frozen-column:nth-child(6) {
  left: 23rem !important;
}

.frozen-client-approval-pa .filingNameColumn-pa .p-column-filter-menu {
  margin-left: 0% !important;
}

// .p-datatable-scrollable .p-frozen-column:nth-child(3),
// .p-datatable-scrollable .p-frozen-column:nth-child(4),
// .p-datatable-scrollable .p-frozen-column:nth-child(5) {
//   position: sticky !important;
//   left: 0 !important;
//   /* Adjust the left position as needed */
//   background-color: inherit !important;
//   /* Inherit background color from parent */
// }

//Frozen client profile column
.frozen-client-profile .p-frozen-column:nth-child(3) {
  left: 0 !important;
}

.frozen-client-profile .p-frozen-column:nth-child(4) {
  left: 11rem !important;
}

.frozen-client-profile .p-frozen-column:nth-child(5) {
  left: 23rem !important;
}

//Frozen Master filing columns
.frozen-mf .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-mf .p-frozen-column:nth-child(5) {
  left: 12rem !important;
}

.frozen-mf .p-frozen-column:nth-child(6) {
  left: 24rem !important;
}

.frozen-mf .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen Master filing upcoming due dates columns
.frozen-mf-ud .p-frozen-column:nth-child(3) {
  left: 0 !important;
}

.frozen-mf-ud .p-frozen-column:nth-child(4) {
  left: 12rem !important;
}

.frozen-mf-ud .p-frozen-column:nth-child(5) {
  left: 24rem !important;
}

.frozen-mf-ud .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen deffered filing column
.frozen-deffered .p-frozen-column:nth-child(3) {
  left: 0 !important;
}

.frozen-deffered .p-frozen-column:nth-child(4) {
  left: 12rem !important;
}

.frozen-deffered .p-frozen-column:nth-child(5) {
  left: 24rem !important;
}

.frozen-deffered .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen tracker upcoming dueDate column
.frozen-tracker-upd .p-frozen-column:nth-child(3) {
  left: 0 !important;
}

.frozen-tracker-upd .p-frozen-column:nth-child(4) {
  left: 11rem !important;
}

.frozen-tracker-upd .p-frozen-column:nth-child(5) {
  left: 24rem !important;
}

.frozen-tracker-upd .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen tracker Active column
.frozen-tracker-active .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-tracker-active .p-frozen-column:nth-child(5) {
  left: 11rem !important;
}

.frozen-tracker-active .p-frozen-column:nth-child(6) {
  left: 23rem !important;
}

.frozen-tracker-active .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

//Tracker active disabled multiselect field overlapping style
.frozen-tracker-active
  .p-datatable-scrollable-table
  > .p-datatable-frozen-tbody,
.frozen-tracker-active .p-datatable-scrollable-table > .p-datatable-tfoot,
.frozen-tracker-active .p-datatable-scrollable-table > .p-datatable-thead {
  z-index: 2;
}

.frozen-tracker-active tbody tr td.filingNameColumn.p-frozen-column {
  z-index: 1;
}

.frozen-tracker-active tbody tr td.internalDueDate.p-frozen-column {
  z-index: 1;
}

.frozen-tracker-active tbody tr td.status.p-frozen-column {
  z-index: 1;
}

// Tracker active loader style
.frozen-tracker-active.p-datatable .p-datatable-loading-overlay {
  z-index: 3;
}

// Tracker history
.frozen-tracker-history .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-tracker-history .p-frozen-column:nth-child(5) {
  left: 11rem !important;
}

.frozen-tracker-history .p-frozen-column:nth-child(6) {
  left: 23rem !important;
}

.frozen-tracker-history .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

// //POSITIONING EDITABLE FIELD TRACKER
// .p-multiselect-panel.p-component.p-ripple-disabled.staffassigned.p-connected-overlay-enter-done {
//   left: 850px !important;
// }

//Frozen filing approval pending columns
.frozen-filing-approval-pending .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-filing-approval-pending .p-frozen-column:nth-child(5) {
  left: 12rem !important;
}

.frozen-filing-approval-pending .p-frozen-column:nth-child(6) {
  left: 24rem !important;
}

.frozen-filing-approval-pending .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen filing approval completed columns
.frozen-filing-approval-completed .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-filing-approval-completed .p-frozen-column:nth-child(5) {
  left: 12rem !important;
}

.frozen-filing-approval-completed .p-frozen-column:nth-child(6) {
  left: 24rem !important;
}

.frozen-filing-approval-completed .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

//Frozen client approval columns
.frozen-client-approval .p-frozen-column:nth-child(4) {
  left: 0 !important;
}

.frozen-client-approval .p-frozen-column:nth-child(5) {
  left: 12rem !important;
}

.frozen-client-approval .p-frozen-column:nth-child(6) {
  left: 23rem !important;
}

.frozen-client-approval .filingNameColumn .p-column-filter-menu {
  margin-left: 0% !important;
}

.p-inputtext {
  text-overflow: ellipsis;
}

.tableBtn .p-button:focus {
  box-shadow: none;
}

.affectedCompanies .p-button {
  padding: 0% !important;
}

.notes .p-button {
  padding: 0% !important;
}

//DataTable cell size

// div.p-datatable .p-datatable-tbody>tr>td {
//   padding: 0.5rem;
// }

.column-small > .p-column-filter-row .p-column-filter-menu-button,
.column-small > .p-column-filter-row .p-column-filter-clear-button {
  margin: 0;
}

.filingPeriodTable div.p-datatable-wrapper {
  height: max-content;
}

.masterFilingApproval .MuiTabPanel-root {
  padding: 0;
}

.reminderContainer.css-873arq-MuiContainer-root {
  max-width: 100%;
}

// @media (min-width: 1200px) {
//   .reminderContainer.css-1x7azcy {
//     max-width: 1350px !important;
//   }
// }
// @media (min-width: 1200px) {
//   .reminderContainer.css-l3j33f {
//     max-width: 1350px !important;
//   }
// }

body .css-1u2w381-MuiModal-root-MuiDrawer-root {
  z-index: 1000;
}

.p-datatable .p-datatable-tbody > tr.completed-row {
  background: #e2fedf;
}

//SCROLL BAR VISIBLE FOR STAFF ASSIGND AND AFFECTED COMPANIES
.scrollbar-tracker .p-scrollpanel .p-scrollpanel-bar {
  background: #b9bbbd !important;
  // overflow: hidden !important;
}

.affectedCompanyForm > div > .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  & > div {
    min-height: 80%;
  }
}

.staffAssignedForm > div > .MuiFormControl-root {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  & > div {
    min-height: 80%;
  }
}

.statusForm > .MuiInputBase-root {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  & > div {
    min-height: 80%;
  }
}

.affectedCompaniesDialog.p-dialog .p-dialog-header {
  padding-bottom: 0.7rem;
}

li.commentText div span {
  font-weight: 600;
}

.fc-toolbar-chunk button.fc-button,
.fc-toolbar-title {
  text-transform: capitalize;
}

h5 {
  font-size: 0.83rem !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  background-color: white;
  color: black;
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button.fc-button-active {
  background-color: #6366f1;
  color: white;
}
