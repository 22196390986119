.nav-bar {
  background: transparent linear-gradient(87deg, #366aaf 0%, #16a2ff 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  height: 100%;
  color: white;
  margin: 0;
  align-items: flex-start;
  &__container {
    margin: 0 0 0 3rem;
    padding: 0 0 0 2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    &__img {
      margin-right: 1rem;
      & img {
        height: 50px;
        width: 50px;
      }
    }
    &__title {
      font-family: "Times New Roman", "Regular";
      font-size: 1.1rem;
      line-height: 20px;
      color: #fff;
    }
  }
  &__popover {
    display: flex;
    align-items: center;
    font-family: "Roboto";
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.2);
    padding: 0.5rem 1rem 0;
    border-radius: 5px;
    height: 7vh;
  }
  &__menu {
    display: flex;
    align-items: center;
    &__item {
      padding: 0 5rem;
    }
    &__group {
      display: flex;
    }
    &__search {
      height: 50px;
      width: 400px;
      position: absolute;
      z-index: 3;
      right: 3rem;
      border: none;
      border-radius: 5px;
      visibility: hidden;
      padding: 0 10px;
      &.show {
        visibility: visible;
      }
    }
    &__logout-btn {
      font-family: "Roboto";
      cursor: pointer;
      color: #fff;
      font-size: 1rem;
      background-color: hsla(0, 0%, 100%, 0.2);
      padding: 0 0rem 0 3rem;
      border-radius: 5px;
      margin: 0 2rem;
      height: 50px;
      overflow: hidden;
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
    &__login-btn {
      font-family: "Roboto";
      cursor: pointer;
      color: #3172ba;
      text-transform: uppercase;
      font-size: 1rem;
      background-color: white;
      padding: 0 0rem 0 3rem;
      border-radius: 5px;
      margin: 0 2rem;
      height: 50px;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    &__icon {
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin-top: 6px;
      left: -1rem;
      position: relative;
    }
    &__button_icon {
      padding: 0 0.5rem 0 0;
    }
    &__search__icon {
      cursor: pointer;
      z-index: 99;
      color: white;
      font-size: 1rem;
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.2);
      padding: 14px;
      border-radius: 50%;
      &.show {
        color: black;
      }
    }
    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__title {
        font-family: "Roboto";
        font-size: 1rem;
        text-align: left;
        line-height: 0.5;
        margin-top: 5px;
      }
      &__desc {
        font-family: "Roboto";
        font-size: 0.8rem;
        text-align: left;
        & a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    &__dropdown {
      margin-right: 2rem;
      &__toggle {
        display: flex;
        &__img {
          height: 50px;
          width: 50px;
          border: 3px solid transparent;
          border-radius: 50%;
          margin-right: 10px;
        }
        &__sub-title {
          font-size: 13px;
          opacity: 0.6;
        }
      }
      & .nav-link {
        color: white;
        margin: 0;
        font-family: "Roboto", sans-serif;
        &:hover {
          color: white;
        }
        &:focus {
          color: black;
        }
      }
      .dropdown-menu[data-bs-popper] {
        width: 100%;
        margin-top: 0;
        padding: 1rem;
      }
      .dropdown-toggle::after {
        content: none;
      }
      &__icon::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f078";
        border-top: none;
        margin-left: 10px;
      }
    }
    &__dropdown.show {
      background-color: white;
      color: black;
      &__icon::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f105";
        border-top: none;
        margin-left: 10px;
      }
    }
    &__dropdown2 {
      margin-right: 2rem;
      &__toggle {
        display: flex;
        &__img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          margin-right: 10px;
          background-color: hsla(0, 0%, 100%, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 1.5rem;
          letter-spacing: -2px;
        }
        &__sub-title {
          font-size: 13px;
          opacity: 0.6;
        }
      }
      & .nav-link {
        color: white;
        &:hover,
        &:focus {
          color: white;
        }
      }
      .dropdown-menu[data-bs-popper] {
        width: 100%;
        margin-top: 0;
        padding: 1rem;
      }
      .dropdown-toggle::after {
        content: none;
      }
      &__icon::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        content: "\f078";
        border-top: none;
        margin-left: 10px;
      }
    }
  }
}
