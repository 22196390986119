body {
  background-color: #f1f2f6;
}

.cfl-nav {
  margin: 20px;
}

.nav-hd {
  padding-left: 40px;
}

.btn2 {
  margin-right: 40px;
}

.btn2 .button {
  background-color: white;
  border: none;
  color: black;
  padding: 13px 39px;
  text-align: center;
  /* display: inline-block; */
  font-size: 12px;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: bold;
}

.btn2 #btn1 {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.btn2 #btn3 {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.btn2 .button :active {
  /* background: rgb(33, 33, 174); */
  /* color: white; */
}

.btn2 .button:focus {
  background: #0000cc;
  color: aliceblue;
}

.underline-bg {
  width: 70px;
  height: 6px;
  border-radius: 10px;
  margin-top: 10px;
  background: linear-gradient(
    to right top,
    #cc04ff,
    #9356fe,
    #6474ff,
    #3588ff,
    #1c97f6
  );
}
